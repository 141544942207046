<template lang="pug">
.page
  .menuList
    .menuItem(v-for="i in menuList" @click="handleClick(i)")
      img.menuIcon(:src="i.icon")
      .menuName {{i.name}}
</template>

<script setup>
import { inject, onMounted, computed, ref } from 'vue'

const { router } = inject('global')

const menuList = ref([
  {
    name: '基础信息管理',
    icon: 'https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/miniapp/market/basic.png',
    path: '/info/basic'
  },
  {
    name: '商场设施管理',
    icon: 'https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/miniapp/market/facility.png',
    path: '/info/desc'
  },
  {
    name: '商场门店管理',
    icon: 'https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/miniapp/market/store.png',
    path: '/store'
  },
  {
    name: '营业时间管理',
    icon: 'https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/miniapp/market/time.png',
    path: '/info/openingTime'
  }
])

function handleClick(val) {
  if (!val.path) return
  router.push(val.path)
}
</script>

<style lang="less" scoped>
.menuList {
  display: flex;
  flex-wrap: wrap;
  .menuItem {
    .trans();
    flex-grow: 1;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background: white;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
    &:hover {
      background: var(--color-primary);
      color: white;
    }
    .menuIcon {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
}
</style>
